import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Participant } from '@app/models/participant';
import { ParticipantService } from '@app/services/participant.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParticipantResolver {
  private participantService = inject(ParticipantService);
  private router = inject(Router);

  resolve(route: ActivatedRouteSnapshot): Observable<Participant> {
    const uuid = route.paramMap.get('uuid');
    const participant = this.router.getCurrentNavigation().extras.state?.participant;

    return participant ? of(participant) : this.participantService.get(uuid);
  }
}

import { Routes } from '@angular/router';
import { ParticipantResolver } from '@app/resolvers/participant.resolver';
import { TabsPage } from '@page/tabs/tabs.page';
import { AppResolver } from '@resolver/app-resolver';
import { ContentItemResolver } from '@resolver/content-item.resolver';

const newsRoutes: Routes = [
  {
    path: 'news',
    children: [
      {
        path: '',
        loadComponent: () => import('@page/content-item-list/content-item-list.page').then(m => m.ContentItemListPage),
      },
      {
        path: ':uuid',
        loadComponent: () => import('@page/content-item/content-item.page').then(m => m.ContentItemPage),
        resolve: {
          contentItem: ContentItemResolver,
        },
      },
      {
        path: ':uuid/:searchValue',
        loadComponent: () => import('@page/content-item/content-item.page').then(m => m.ContentItemPage),
        resolve: {
          contentItem: ContentItemResolver,
        },
      },
    ],
  },
  {
    path: 'news-swiper/:uuid',
    loadComponent: () => import('@page/content-item-swiper/content-item-swiper.page').then(m => m.ContentItemSwiperPage),
    resolve: {
      contentItem: ContentItemResolver,
    },
  },
];

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/tabs',
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () => import('@page/tabs/tabs.page').then(m => m.TabsPage),
    resolve: { app: AppResolver },
  },
  {
    path: 'register',
    loadComponent: () => import('@page/register/register.page').then(m => m.RegisterPage),
  },
  {
    path: 'follow',
    loadComponent: () => import('@page/follow/follow.page').then(m => m.FollowPage),
  },
  {
    path: 'error',
    loadComponent: () => import('@page/error/error.page').then(m => m.ErrorPage),
  },
  {
    path: 'tabs',
    component: TabsPage,
    resolve: { app: AppResolver },
    children: [
      {
        path: '',
        redirectTo: 'ad6',
        pathMatch: 'full',
      },
      {
        path: 'ad6',
        children: [
          {
            path: '',
            loadComponent: () => import('@page/ad6/ad6.page').then(m => m.Ad6Page),
          },
          ...newsRoutes,
        ],
      },
      {
        path: 'live',
        children: [
          {
            path: '',
            loadComponent: () => import('@page/live/live.page').then(m => m.LivePage),
          },
          {
            path: 'participant/:uuid',
            loadComponent: () => import('@page/participant/participant.page').then(m => m.ParticipantPage),
            resolve: {
              participant: ParticipantResolver,
            },
          },
          {
            path: 'teams',
            children: [
              {
                path: '',
                loadComponent: () => import('@page/teams/teams.page').then(m => m.TeamsPage),
              },
              {
                path: ':uuid',
                loadComponent: () => import('@page/team/team.page').then(m => m.TeamPage),
              },
            ],
          },
        ],
      },
      {
        path: 'favorites',
        children: [
          {
            path: '',
            loadComponent: () => import('@page/favorites/favorites.page').then(m => m.FavoritesPage),
          },
          {
            path: 'participants',
            loadComponent: () => import('@page/participants/participants.page').then(m => m.ParticipantsPage),
          },
          {
            path: 'participant/:uuid',
            loadComponent: () => import('@page/participant/participant.page').then(m => m.ParticipantPage),
            resolve: {
              participant: ParticipantResolver,
            },
          },
          {
            path: 'teams',
            children: [
              {
                path: '',
                loadComponent: () => import('@page/teams/teams.page').then(m => m.TeamsPage),
              },
              {
                path: ':uuid',
                loadComponent: () => import('@page/team/team.page').then(m => m.TeamPage),
              },
            ],
          },
        ],
      },
      {
        path: 'more',
        children: [
          {
            path: '',
            loadComponent: () => import('@page/more/more.page').then(m => m.MorePage),
          },
          ...newsRoutes,
          {
            path: 'albums',
            children: [
              {
                path: '',
                loadComponent: () => import('@page/albums/albums.page').then(m => m.AlbumsPage),
              },
              {
                path: ':id',
                loadComponent: () => import('@page/album/album.page').then(m => m.AlbumPage),
              },
            ],
          },
          {
            path: 'about',
            loadComponent: () => import('@page/about/about.page').then(m => m.AboutPage),
          },
          {
            path: 'notifications',
            loadComponent: () => import('@page/push-notifications/push-notifications.page').then(m => m.PushNotificationsPage),
          },
          {
            path: 'settings',
            loadComponent: () => import('@page/settings/settings.page').then(m => m.SettingsPage),
          },
        ],
      },

    ],
  },
];

import { inject, Injectable } from '@angular/core';
import { AppDataService } from '@service/app-data.service';
import { ContentService } from '@service/content.service';
import { EventService } from '@service/event.service';
import { MenuService } from '@service/menu.service';
import { NetworkService } from '@service/network.service';
import { ParticipantService } from '@service/participant.service';
import { PositionService } from '@service/position.service';
import { PushService } from '@service/push.service';
import { concatMap, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

@Injectable({
  providedIn: 'root',
})
export class AppResolver {

  private contentService = inject(ContentService);
  private eventService = inject(EventService);
  private menuService = inject(MenuService);
  private networkService = inject(NetworkService);
  private participantService = inject(ParticipantService);
  private positionService = inject(PositionService);
  private pushService = inject(PushService);
  private appDataService = inject(AppDataService);

  resolve(): Observable<void> {

    return fromPromise(this.appDataService.initialize())
      .pipe(
        concatMap(() => this.eventService.initialize()),
        concatMap(() => this.pushService.initialize()),
        concatMap(() => this.networkService.initialize()),
        concatMap(() => this.positionService.initialize()),
        concatMap(() => this.contentService.initialize()),
        concatMap(() => this.menuService.initialize()),
        concatMap(() => this.participantService.initialize()),
      );
  }
}
